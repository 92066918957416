import gql from "graphql-tag";

const CREATE_ORDER = gql`
mutation CREATE_ORDER($input: CreateOrderInput!) {
      createOrder(input: $input) {
    order {
      id
      orderKey
      orderId
      status
      customer {
        customerId
      }
      lineItems {
        nodes {
          itemId
          productId
          variationId
        }
      }
    }
  }
  }
`;

export default CREATE_ORDER;