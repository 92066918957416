const clientConfig = {
 // productImagePlaceholder: 'https://via.placeholder.com/434', // e.g https://via.placeholder.com/434 - Placeholder image URL for index page
  //singleImagePlaceholder: 'https://via.placeholder.com/200', // e.g https://via.placeholder.com/200 - Placeholder image URL for individual product page
  thumbImagePlaceholder: "/img/no-img-product-100x100.jpg",
  flyCartImagePlaceholder: "/img/no-img-product-120x124.jpg",
  cartImagePlaceholder: "/img/no-img-product-150x150.jpg",
  productImagePlaceholder: "/img/no-img-product-282x300.jpg",
  productImagePlaceholder282: "/img/no-img-product-282x282.jpg",
  singleImagePlaceholder: "/img/no-img-product-690x690.jpg",
  spinner: "/cart-spinner.gif",
  favicon: "/favicon.ico",
  master: "/img/mastercard-icon.png",
  visa: "/img/visa-icon.png",
  amex: "/img/amex-icon.png",
  cvc:"/img/cvc-icon.png",
  bag:"/img/bag-icon.png",
  ok:"/img/ok-icon.png",
  code:"/img/code-icon.png",
  efectyIcon:"/img/efecty-icon.png",
  time:"/img/time-icon.png",
  cedula:"/img/cedula-icon.png",
  credential:"/img/credential-icon.png",
  phone:"/img/phone-icon.png",
  cancel:"/img/cancel-icon.png",
  logo:"/img/logo-classicjeans.svg",
  sic:"/img/logo-sic-footer.png",
  home:"/img/logo-home.png",
  shop: "/img/logo-shop.png",
  addi: "/img/logo-addi.png",
  efecty:"/img/logo-efecty.png",
  pse:"/img/logo-pse.png",
  bancolombia:"/img/logo-bancolombia.png",
  efectivo: "/img/logo-efectivo.png",
  cards: "/img/logo-cards.png",
  nequi: "/img/logo-nequi.png",
  contraEntrega: "/img/logo-cash-on-delivery.png",
  callCenter:"573103662024",
};

export default clientConfig;
