import gql from "graphql-tag";

const GET_CART = gql`
  query GET_CART {
    cart {
    contents(first: 100) {
      nodes {
        key
        product {
          id
          productId
          sku
          name
          description
          shortDescription
          type
          onSale
          slug
          averageRating
          reviewCount
          image {
            id
            guid
          }
        }
        variation {
          id
          variationId
          sku
          name
          description
          type
          onSale
          price
          regularPrice
          salePrice
          stockQuantity
          stockStatus
          image {
            guid
          }
          attributes {
            nodes {
              id
              name
              value
            }
          }
        }
        quantity
        total
        subtotal
        subtotalTax
      }
    }
    appliedCoupons {
      nodes {
        id
        couponId
        discountType
        amount
        dateExpiry
        products {
          nodes {
            id
          }
        }
        productCategories {
          nodes {
            id
          }
        }
      }
    }
    subtotal
    subtotalTax
    shippingTax
    shippingTotal
    total
    totalTax
    feeTax
    feeTotal
    discountTax
    discountTotal
  }
  }
`;

export default GET_CART;
