/* eslint-disable react/prop-types */
import React from "react";

const TruncateText = ({ text, maxLength }) => {
  const truncate = (inputText, maxChars) => {
    if (inputText.length > maxChars) {
      return inputText.substring(0, maxChars - 3) + "...";
    }
    return inputText;
  };

  return (
    <p className="truncate overflow-ellipsis whitespace-nowrap">
      {truncate(text, maxLength)}
    </p>
  );
};

export default TruncateText;