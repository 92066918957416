import { v4 } from "uuid";
import clientConfig from "../client-config";
import { isEmpty } from "lodash";
import { browserName, browserVersion, osVersion, osName } from "react-device-detect";

/**
 * Extracts and returns float value from a string.
 *
 * @param {string} string String
 * @return {any}
 */

export const getFloatVal = (string) => {

	//let floatValue = string.match(/[+-]?\d+(\.\d+)?/g)[0];//cambiar . por , para obtener los miless
	//parseFloat(parseFloat(floatValue).toFixed(3))
	var floatValue;

	if ("string" !== typeof string) {
		floatValue = string;
	} else {
		var extract1 = string.split("$");
		extract1 = extract1[extract1.length - 1];
		floatValue = parseFloat(extract1.replace(/\./g, "").replace(",", "."));
	}
	return (null !== floatValue) ? floatValue : "";
};

export const roundToStep = (value, stepParam) => {
	var step = stepParam || 1.0;
	var inv = 1.0 / step;
	return Math.round(value * inv) / inv;
};

export const mySalePrice = (regularPrice, salesPrice) => {
	let salePrice = salesPrice;
	if (isEmpty(salesPrice)) {
		salePrice = regularPrice;
	}
	let extract1 = salePrice.split("- ");
	extract1 = extract1[0];
	return getFloatVal(extract1);
};

export const formatPrice = (price) => {
	let tmpPrice = "", finalPrice = "";

	if (price >= 1000000) {
		tmpPrice = price.toString();
		tmpPrice = tmpPrice.replace(".", ",");
		tmpPrice = tmpPrice.replace(/\B(?=(\d{3})(?!\d))/g, ".");
		tmpPrice = tmpPrice.split(".");
		tmpPrice[0] = tmpPrice[0].replace(/\B(?=(\d{3})(?!\d))/g, ".");
		finalPrice = tmpPrice[0] + "." + tmpPrice[1];
	} else {
		tmpPrice = price.toString();
		tmpPrice = tmpPrice.replace(".", ",");
		finalPrice = tmpPrice.replace(/\B(?=(\d{3})(?!\d))/g, ".");
	}

	finalPrice = "$" + finalPrice;

	return finalPrice;
};

export const priceToFloat = (price1, price2, operator = "+") => {

	var valueFloat1;
	var valueFloat2;
	if ("string" !== typeof price1) {
		valueFloat1 = price1;
	} else {
		var extract1 = price1.split("$");
		extract1 = extract1[extract1.length - 1];
		valueFloat1 = parseFloat(extract1.replace(/\./g, "").replace(",", "."));
	}

	if ("string" !== typeof price2) {
		valueFloat2 = price2;
	} else {
		var extract2 = price2.split("$");
		extract2 = extract2[extract2.length - 1];
		valueFloat2 = parseFloat(extract2.replace(/\./g, "").replace(",", "."));
	}
	var total;
	if (operator == "+")
		total = roundToStep(valueFloat1 + valueFloat2, 1.0);
	else if (operator == "-") total = roundToStep(valueFloat1 - valueFloat2, 1.0);

	total = formatPrice(total);

	return total;
};

export const ivaIncluido = (value, taxRate) => {
	var valueFloat1;
	var result;
	if ("string" !== typeof value) {
		valueFloat1 = value;
	} else {
		var extract1 = value.split("$");
		extract1 = extract1[extract1.length - 1];
		valueFloat1 = parseFloat(extract1.replace(/\./g, "").replace(",", "."));
	}

	if (taxRate == "STANDARD") {
		result = valueFloat1 * 1.19;
	}
	else {
		result = valueFloat1;
	}

	var total = roundToStep(result, 1.0);

	total = total.toString();
	total = total.replace(".", ",");
	total = total.replace(/\B(?=(\d{3})(?!\d))/g, ".");

	return total;

};

export const resumenTotal = (cart, costoEnvio, valueContraEntrega) => {
	if (cart == null) return;
	let total;
	let cartTotal = cart.totalProductsPrice.split("$");
	cartTotal = cartTotal[cartTotal.length - 1];
	let valueFloat = parseFloat(cartTotal.replace(/\./g, "").replace(",", "."));
	let contraEntrega = JSON.parse(localStorage.getItem("classic-contraEntrega"));
	//valueFloat = valueFloat - infoCupon;

	if (cartTotal && contraEntrega) {
		//var valueContraEntrega = JSON.parse(localStorage.getItem("classic-valueContraEntrega"));
		if (costoEnvio) {
			total = valueFloat + getFloatVal(costoEnvio) + getFloatVal(valueContraEntrega);
		} else {
			if (isEmpty(valueContraEntrega)) {
				total = valueFloat;
			}
			else {
				total = valueFloat + getFloatVal(valueContraEntrega);
			}
		}
		total = total.toString();// + centavos;
		//total = total.replace(/\B(?=(\d{2})(?!\d))/g, ",");
		total = total.replace(".", ",");
		total = total.replace(/\B(?=(\d{3})(?!\d))/g, ".");
	}
	if (!contraEntrega) {
		if (!isEmpty(costoEnvio)) {
			total = valueFloat + getFloatVal(costoEnvio);
			total = total.toString();// + centavos;
			//total = total.replace(/\B(?=(\d{2})(?!\d))/g, ",");
			total = total.replace(".", ",");
			total = total.replace(/\B(?=(\d{3})(?!\d))/g, ".");
		} else {
			// if ((infoCupon != 0) && !activeDiscount) {
			// 	total = valueFloat.toString();// + centavos;				
			// 	total = total.replace(".", ",");
			// 	total = total.replace(/\B(?=(\d{3})(?!\d))/g, ".");
			// }
			// else {
			total = cartTotal;
			//}
		}
	}

	return total;

};



/**
 * Add first product.
 *
 * @param {Object} product Product
 * @return {{totalProductsCount: number, totalProductsPrice: any, products: Array}}
 */
export const addFirstProduct = (product) => {

	let productPrice = getFloatVal(product.price);

	let newCart = {
		products: [],
		totalProductsCount: 1,
		totalProductsPrice: productPrice
	};

	const newProduct = createNewProduct(product, productPrice, 1);
	newCart.products.push(newProduct);

	//	if ( typeof window !== "undefined" ) {		
	localStorage.setItem("classic-cart", JSON.stringify(newCart));
	//}

	return newCart;
};

/**
 * Create a new product object.
 *
 * @param {Object} product Product
 * @param {Integer} productPrice Product Price
 * @param {Integer} qty Quantity
 * @return {{image: *, productId: *, totalPrice: number, price: *, qty: *, name: *}}
 */
export const createNewProduct = (product, productPrice, qty) => {

	return {
		productId: product.productId,
		image: product.image,
		name: product.name,
		price: productPrice,
		qty,
		totalPrice: parseFloat((productPrice * qty).toFixed(2))
	};

};

/**
 * Updates the existing cart with new item.
 *
 * @param {Object} existingCart Existing Cart.
 * @param {Object} product Product.
 * @param {Integer} qtyToBeAdded Quantity.
 * @param {Integer} newQty New Qty to be updated.
 * @return {{totalProductsCount: *, totalProductsPrice: *, products: *}}
 */
export const updateCart = (existingCart, product, qtyToBeAdded, newQty = false) => {

	const updatedProducts = getUpdatedProducts(existingCart.products, product, qtyToBeAdded, newQty);

	const addPrice = (total, item) => {
		total.totalPrice += item.totalPrice;
		total.qty += item.qty;

		return total;
	};

	// Loop through the updated product array and add the totalPrice of each item to get the totalPrice
	let total = updatedProducts.reduce(addPrice, { totalPrice: 0, qty: 0 });

	const updatedCart = {
		products: updatedProducts,
		totalProductsCount: parseInt(total.qty),
		totalProductsPrice: parseFloat(total.totalPrice)
	};

	//	if ( typeof window !== "undefined" ) {		
	localStorage.setItem("classic-cart", JSON.stringify(updatedCart));
	//	}


	return updatedCart;
};

/**
 * Get updated products array
 * Update the product if it exists else,
 * add the new product to existing cart,
 *
 * @param {Object} existingProductsInCart Existing product in cart
 * @param {Object} product Product
 * @param {Integer} qtyToBeAdded Quantity
 * @param {Integer} newQty New qty of the product (optional)
 * @return {*[]}
 */
export const getUpdatedProducts = (existingProductsInCart, product, qtyToBeAdded, newQty = false) => {

	// Check if the product already exits in the cart.
	const productExitsIndex = isProductInCart(existingProductsInCart, product.productId);

	// If product exits ( index of that product found in the array ), update the product quantity and totalPrice
	if (-1 < productExitsIndex) {
		let updatedProducts = existingProductsInCart;
		let updatedProduct = updatedProducts[productExitsIndex];

		// If have new qty of the product available, set that else add the qtyToBeAdded
		updatedProduct.qty = (newQty) ? parseInt(newQty) : parseInt(updatedProduct.qty + qtyToBeAdded);
		updatedProduct.totalPrice = parseFloat((updatedProduct.price * updatedProduct.qty).toFixed(2));

		return updatedProducts;
	} else {

		// If product not found push the new product to the existing product array.
		let productPrice = getFloatVal(product.price);
		const newProduct = createNewProduct(product, productPrice, qtyToBeAdded);
		existingProductsInCart.push(newProduct);

		return existingProductsInCart;
	}
};

/**
 * Returns index of the product if it exists.
 *
 * @param {Object} existingProductsInCart Existing Products.
 * @param {Integer} productId Product id.
 * @return {number | *} Index Returns -1 if product does not exist in the array, index number otherwise
 */
const isProductInCart = (existingProductsInCart, productId) => {

	const returnItemThatExits = (item) => {
		if (productId === item.productId) {
			return item;
		}
	};

	// This new array will only contain the product which is matched.
	const newArray = existingProductsInCart.filter(returnItemThatExits);

	return existingProductsInCart.indexOf(newArray[0]);
};

/**
 * Remove Item from the cart.
 *
 * @param {Integer} productId Product Id.
 * @return {any | string} Updated cart
 */
export const removeItemFromCart = (productId) => {

	//if ( typeof window !== "undefined" ) {					
	let existingCart = localStorage.getItem("classic-cart");
	existingCart = JSON.parse(existingCart);
	//	}
	// If there is only one item in the cart, delete the cart.
	if (1 === existingCart.products.length) {

		localStorage.removeItem("classic-cart");
		return null;

	}

	// Check if the product already exits in the cart.
	const productExitsIndex = isProductInCart(existingCart.products, productId);

	// If product to be removed exits
	if (-1 < productExitsIndex) {

		const productTobeRemoved = existingCart.products[productExitsIndex];
		const qtyToBeRemovedFromTotal = productTobeRemoved.qty;
		const priceToBeDeductedFromTotal = productTobeRemoved.totalPrice;

		// Remove that product from the array and update the total price and total quantity of the cart
		let updatedCart = existingCart;
		updatedCart.products.splice(productExitsIndex, 1);
		updatedCart.totalProductsCount = updatedCart.totalProductsCount - qtyToBeRemovedFromTotal;
		updatedCart.totalProductsPrice = updatedCart.totalProductsPrice - priceToBeDeductedFromTotal;

		//if ( typeof window !== "undefined" ) {		
		localStorage.setItem("classic-cart", JSON.stringify(updatedCart));
		//	}
		return updatedCart;

	} else {
		return existingCart;
	}
};

/**
 * Returns cart data in the required format.
 * @param {String} data Cart data
 */
export const getFormattedCart = (data) => {

	let formattedCart = null;

	if (undefined === data || !data.cart.contents.nodes.length) {
		return formattedCart;
	}

	const givenProducts = data.cart.contents.nodes;

	// Create an empty object.
	formattedCart = {};
	formattedCart.products = [];
	let totalProductsCount = 0;


	for (let i = 0; i < givenProducts.length; i++) {
		const givenProduct = givenProducts[i].product;

		const total = getFloatVal(givenProducts[i].total);
		const product = {};
		product.productId = givenProduct.productId;
		product.cartKey = givenProducts[i].key;
		product.name = givenProduct.name;
		product.shortName = givenProduct.shortDescription;
		product.slug = givenProduct.slug;

		const givenVariation = givenProducts[i]?.variation;
		if (!givenVariation) return formattedCart;

		product.variationId = givenVariation?.variationId;

		var tmp = givenVariation.sku.split("-");
		product.codigoERP = tmp[tmp.length - 1];
		product.bodega = tmp[3];
		product.origen = tmp[4];

		for (let j = 0; j < givenVariation.attributes.nodes.length; j++) {
			const attr = givenVariation.attributes.nodes[j];
			if (attr.name == "pa_talla")
				product.talla = attr.value;
			else
				product.color = attr.value;

		}
		product.qty = givenProducts[i].quantity;
		product.stockQuantity = givenVariation.stockQuantity;
		product.price = total / product.qty;
		product.totalPrice = priceToFloat(givenProducts[i].total, givenProducts[i].subtotalTax);//givenProducts[i].total;
		product.image = {
			sourceUrl: givenVariation.image !== null ? givenVariation.image.guid : clientConfig.cartImagePlaceholder,
			srcSet: givenVariation.image !== null ? givenVariation.image.guid : clientConfig.cartImagePlaceholder,
			guid: givenVariation.image !== null ? givenVariation.image.guid : clientConfig.cartImagePlaceholder,
			title: givenVariation.image !== null ? givenVariation.image.title : ""
		};

		totalProductsCount += givenProducts[i].quantity;

		// Push each item into the products array.
		formattedCart.products.push(product);
	}

	formattedCart.totalProductsCount = totalProductsCount;
	formattedCart.totalProductsPrice = data.cart.total;

	return formattedCart;

};

/**
 * Returns cart data in the required format for Whatsapp.
 * @param {String} data Cart data
 */
export const getFormattedCartToWhatsapp = (data) => {

	let formattedCart = "https://api.whatsapp.com/send?phone=" + clientConfig.callCenter + "&text=Hola, deseo continuar la compra de estos artículos por aquí:%0A";

	if (undefined === data || !data) {
		return formattedCart;
	}

	for (let i = 0; i < data.products.length; i++) {
		let number = i + 1;
		formattedCart += number + "-" + data.products[i].name + "%0ACódigo: " + data.products[i].codigoERP + "%0ABodega: " + data.products[i].bodega + "%0ATalla: " + data.products[i].talla + "%0AColor: " + data.products[i].color + "%0ACantidad: " + data.products[i].qty + "%0APrecio: %24" + data.products[i].totalPrice + "%0AEnlace: https://classicjeans.co/" + data.products[i].slug + "%0A______________________________________________________________%0A";
	}

	formattedCart += "Precio Total: " + data.totalProductsPrice;

	return formattedCart;
};

export const createCheckoutData = (order) => {
	let checkoutData = {
		clientMutationId: v4(),
		customerId: order.datosContacto.customerId,
		billing: {
			firstName: order.datosContacto.firstName,
			lastName: order.datosContacto.lastName,
			postcode: order.datosContacto.id,
			email: order.datosContacto.email,
			phone: order.datosContacto.celular,
			company: order.company,
		},
		shipping: {
			firstName: order.datosContacto.firstName,
			lastName: order.datosContacto.lastName,
			email: order.datosContacto.email,
			phone: order.datosContacto.celular,
			company: order.company,
		},
		lineItems: [],
		metaData: [
			{
				key: "tipoId",
				value: order.datosContacto.cedula,
			},
			{
				key: "Paso 1",
				value: "Informacion -> Direcciones",
			}, {
				key: "Dispositivo Usado",
				value: browserName + "-" + browserVersion + "-" + osName + "-" + osVersion,
			}],
		isPaid: false,
		transactionId: ""
	};

	return checkoutData;
};


export const updateCheckoutData = (order) => {
	let checkoutData = {
		clientMutationId: v4(),
		billing: {
			address1: order.envio.address1 ? order.envio.address1 : order.envio.store,
			city: order.envio.city + "-" + order.envio.cityName,
			country: "CO",
			state: order.envio.dept + "-" + order.envio.deptName,
		},
		shipping: {
			address1: order.envio.address1 ? order.envio.address1 : order.envio.store,
			address2: order.envio.address2 ? order.envio.address2 : "",
			city: order.envio.city + "-" + order.envio.cityName,
			country: "CO",
			state: order.envio.dept + "-" + order.envio.deptName,
			postcode: order.envio.store ? order.activeStore + "-" + order.envio.store : "",
		},
		lineItems: [],
		metaData: [
			{
				key: "Paso 2",
				value: "Direcciones -> Pago",
			}
		],
		isPaid: false,
		transactionId: ""
	};

	// order.cart.products.forEach(element => {
	// 	var valueToPush = { productId: 0, quantity: 0, variationId: 0 };
	// 	valueToPush["productId"] = element.productId;
	// 	valueToPush["quantity"] = element.qty;
	// 	valueToPush["variationId"] = element.variationId;
	// 	checkoutData.lineItems.push(valueToPush);
	// });


	//checkoutData = Object.assign(checkoutData, product);
	return checkoutData;
};

export const formatItemsToOrder = (cart) => {
	let checkoutData = {
		lineItems: [],
	};

	cart.products.forEach(element => {
		let valueToPush = { productId: 0, quantity: 0, variationId: 0 };
		valueToPush["productId"] = element.productId;
		valueToPush["quantity"] = element.qty;
		valueToPush["variationId"] = element.variationId;
		checkoutData.lineItems.push(valueToPush);
	});

	return checkoutData;
};

/**
 * Get the updated items in the below format required for mutation input.
 *
 * [
 * { "key": "33e75ff09dd601bbe6dd51039152189", "quantity": 1 },
 * { "key": "02e74f10e0327ad868d38f2b4fdd6f0", "quantity": 1 },
 * ]
 *
 * Creates an array in above format with the newQty (updated Qty ).
 *
 */
export const getUpdatedItems = (products, newQty, cartKey) => {

	// Create an empty array.
	const updatedItems = [];

	// Loop through the product array.
	products.map((cartItem) => {

		// If you find the cart key of the product user is trying to update, push the key and new qty.
		if (cartItem.cartKey === cartKey) {

			if (parseInt(newQty) <= cartItem.stockQuantity) {
				updatedItems.push({
					key: cartItem.cartKey,
					quantity: parseInt(newQty)
				});

				//	if(parseInt(newQty) == 0){
				let tmpProducts = {};
				tmpProducts.products = [];
				tmpProducts.totalProductsCount = 0;
				tmpProducts.totalProductsPrice = 0;

				let cart = JSON.parse(localStorage.getItem("classic-cart"));

				for (let i = 0; i < cart.products.length; i++) {
					if (cart.products[i].cartKey != cartKey) {
						tmpProducts.products.push(cart.products[i]);
						tmpProducts.totalProductsCount += cart.products[i].qty;
						tmpProducts.totalProductsPrice += cart.products[i].qty * cart.products[i].price;
					} else if (newQty > 0) {
						cart.products[i].qty = newQty;
						tmpProducts.products.push(cart.products[i]);
						tmpProducts.totalProductsCount += cart.products[i].qty;
						tmpProducts.totalProductsPrice += cart.products[i].qty * cart.products[i].price;
					}
				}
				if (tmpProducts.products.length == 0) {
					tmpProducts = null;
				} else {
					tmpProducts.totalProductsPrice = formatPrice(tmpProducts.totalProductsPrice);
				}

				localStorage.setItem("classic-cart", JSON.stringify(tmpProducts));
				//}
			}
			else {
				updatedItems.push({
					key: cartItem.cartKey,
					quantity: cartItem.qty
				});
			}

			// Otherwise just push the existing qty without updating.
		} else {
			if (cartKey == "") {
				updatedItems.push({
					key: cartItem.cartKey,
					quantity: newQty
				});
			}
			else {
				updatedItems.push({
					key: cartItem.cartKey,
					quantity: cartItem.qty
				});
			}
		}
	});

	// Return the updatedItems array with new Qtys.
	return updatedItems;

};

/**
 * Returns sorted object by descendent date.
 * @arrayElements {object}
 */
export const sortByDate = (arrayElements) => {
	let myArray = [...arrayElements];
	return myArray.sort(function (x, y) {
		var firstDate = new Date(x.fecha);
		var SecondDate = new Date(y.fecha);
		if (firstDate > SecondDate) return -1;
		if (firstDate < SecondDate) return 1;
		return 0;
	});

};

export const filterDuplicate = (arraElements) => {
	var hash = {};

	return arraElements.filter(function (current) {
		var exists = !hash[current];
		hash[current] = true;
		return exists;
	});

};

export const filterDuplicateColor = (arraElements) => {
	var hash = {};
	if (!isEmpty(arraElements)) {
		return arraElements.filter(function (current) {
			var exists = !hash[current.color];
			hash[current.color] = true;
			return exists;
		});
	}
};

export const searchColor = (nameKey, myArray) => {
	for (let i = 0; i < myArray.length; i++) {
		if (myArray[i].color === nameKey) {
			return myArray[i];
		}
	}
};

export const filterDuplicateTalla = (arraElements) => {
	var hash = {};
	if (!isEmpty(arraElements)) {
		return arraElements.filter(function (current) {
			var exists = !hash[current.talla];
			hash[current.talla] = true;
			return exists;
		});
	}
};

export const removeFilter = (arr, target) => {
	return arr.filter(item => item != target);
	// var idx = arr.indexOf(target);
	// if (idx > -1) {
	// 	arr.splice(idx, 1);
	// }
	// return arr;
};

export const createSlug = (filtros) => {
	var slug = [];
	var i = 0;
	Object.keys(filtros).map(function (taxonomy) {
		if (filtros[taxonomy].length) {   //armar un slug con los filtros
			slug[i] = taxonomy + "=";
			filtros[taxonomy].map(function (item, index) {
				if (taxonomy != "paTallas" && taxonomy != "paColoresmayors") {
					item = item.replace(/\s/g, "-");//replace space by -
				}
				if (index != filtros[taxonomy].length - 1)
					slug[i] += item + "+";
				else slug[i] += item;
			});
			i++;
		}
	});

	let tmpSlug = "";
	for (let index = 0; index < slug.length; index++) {
		if (index != slug.length - 1)
			tmpSlug += slug[index] + "&";
		else tmpSlug += slug[index];
	}

	return tmpSlug;
};


export const productsWithImages = (AllProducts) => {
	var FiltersProducts = [];

	AllProducts.map(function (product) {
		if (product.image != null)
			FiltersProducts.push(product);
	});

	return FiltersProducts;
};

/*
* Function to sort item to more fresh to less
*/
export const rowCreate = (data, total) => {
	let temporal = [];
	let dataToShow = [];
	let i = 0, j = 0;
	if (data?.length > 0) {
		data.map(item => {
			let skuSplit = item.sku.split("-");
			let cod = skuSplit[skuSplit.length - 1];
			let tmp = Object.assign({}, item, { order: cod });
			temporal.push(tmp);
		});
		temporal.sort((a, b) => {
			if (a.order > b.order) {
				return -1;
			}
			if (a.order < b.order) {
				return 1;
			}
			return 0;
		});

		while (j < total && temporal[i] != undefined) {
			if (temporal[i]?.variations?.nodes?.length > 0) {
				dataToShow[j] = temporal[i];
				j++;
			}
			i++;
		}
	}
	return dataToShow;
};

export const rowCreateByDate = (data, total) => {
	let temporal = [];
	let dataToShow = [];
	let i = 0, j = 0;
	if (data?.length > 0) {
		data.map(item => {
			let tmpDate = item?.paFechaingresocedis?.nodes[0]?.name;
			let tmp = Object.assign({}, item, { admissionDate: tmpDate });
			temporal.push(tmp);
		});
		const sortedProducts = [...temporal].sort((a, b) => new Date(b.admissionDate) - new Date(a.admissionDate));

		while (j < total && sortedProducts[i] != undefined) {
			if (sortedProducts[i]?.variations?.nodes?.length > 0) {
				dataToShow[j] = sortedProducts[i];
				j++;
			}
			i++;
		}
	}
	return dataToShow;
};

export const rowCreateByDateAndTerm = (data, term, total) => {
	let temporal = [];
	let dataToShow = [];
	let i = 0, j = 0;
	if (data?.length > 0) {
		data.map(item => {
			let tmpDate = item?.paFechaingresocedis?.nodes[0]?.name;
			let tmp = Object.assign({}, item, { admissionDate: tmpDate ? tmpDate : null });
			temporal.push(tmp);
		});
		const sortedProducts = [...temporal].sort((a, b) => new Date(b.admissionDate) - new Date(a.admissionDate));

		while (j < total && sortedProducts[i] != undefined) {
			if (sortedProducts[i]?.variations?.nodes?.length > 0 && sortedProducts[i]?.paLineaPorTipoTelas?.nodes[0]?.slug == term) {
				dataToShow[j] = sortedProducts[i];
				j++;
			}
			i++;
		}
	}
	return dataToShow;
};

export const rowCreateByDateAndPriceLine = (data, term, total) => {
	let temporal = [];
	let dataToShow = [];
	let i = 0, j = 0;
	if (data?.length > 0) {
		data.map(item => {
			let tmpDate = item?.paFechaingresocedis?.nodes[0]?.name;
			let tmp = Object.assign({}, item, { admissionDate: tmpDate ? tmpDate : null });
			temporal.push(tmp);
		});
		const sortedProducts = [...temporal].sort((a, b) => new Date(b.admissionDate) - new Date(a.admissionDate));

		while (j < total && sortedProducts[i] != undefined) {
			if (sortedProducts[i]?.variations?.nodes?.length > 0 && sortedProducts[i]?.paLineaPorPrecioActuals?.nodes[0]?.slug == term) {
				dataToShow[j] = sortedProducts[i];
				j++;
			}
			i++;
		}
	}
	return dataToShow;
};

export const rowCreateByDateAndPromotion = (data, term, total) => {
	let temporal = [];
	let dataToShow = [];
	let i = 0, j = 0;
	if (data?.length > 0) {
		data.map(item => {
			let tmpDate = item?.paFechaingresocedis?.nodes[0]?.name;
			let tmp = Object.assign({}, item, { admissionDate: tmpDate ? tmpDate : null });
			temporal.push(tmp);
		});
		const sortedProducts = [...temporal].sort((a, b) => new Date(b.admissionDate) - new Date(a.admissionDate));

		while (j < total && sortedProducts[i] != undefined) {
			if (sortedProducts[i]?.variations?.nodes?.length > 0 && sortedProducts[i]?.paPromocions?.nodes[0]?.slug == term) {
				dataToShow[j] = sortedProducts[i];
				j++;
			}
			i++;
		}
	}
	return dataToShow;
};

/*
* Function to sort item by price
*/
export const sortProducts = (data, typeSort) => {
	let temporal = [];
	let dataToShow = [];
	let i = 0, j = 0;
	if (data?.length > 0) {
		switch (typeSort) {
			case "more_fresh":
				data.map(item => {
					let tmpDate = item?.paFechaingresocedis?.nodes[0]?.name;
					let tmp = Object.assign({}, item, { admissionDate: tmpDate ? tmpDate : null });
					temporal.push(tmp);
				});
				temporal = [...temporal].sort((a, b) => new Date(b.admissionDate) - new Date(a.admissionDate));

				break;
			case "rising_price":
				temporal = [...data];
				temporal.sort((a, b) => getFloatVal(a.regularPrice) - getFloatVal(b.regularPrice));
				break;
			case "falling_price":
				temporal = [...data];
				temporal.sort((a, b) => getFloatVal(b.regularPrice) - getFloatVal(a.regularPrice));
				break;
			default:
				break;
		}

		while (j < data.length && temporal[i] != undefined) {
			if (temporal[i]?.variations?.nodes?.length > 0) {
				dataToShow[j] = temporal[i];
				j++;
			}
			i++;
		}
	}
	return dataToShow;
};


export const capitalizeText = (text) => {
	return text?.charAt(0).toUpperCase() + text?.slice(1).toLowerCase();
};

/**
 * Returns id transaction.
 * 
 */
export const paymentCard = (dato, dataTransactions, keyWompi) => {

	const targetCreditDeb = {
		number: dato.numberCard,
		cvc: dato.CVC,
		exp_month: dato.monthExp,
		exp_year: dato.yearExp,
		card_holder: dato.nameCard,
	};
	const datoCard = {
		payment_method: {
			type: "CARD",
			installments: dato.cuots,
			token: ""
		}
	};
	var transaction = "";
	Object.assign(dataTransactions, datoCard);
	var bearer = keyWompi.pub;
	transaction = fetch(keyWompi.url + "tokens/cards",
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearer,
			},
			body: JSON.stringify(targetCreditDeb),
		})
		.then(res => res.json())
		.then(res => {
			if (res.data) {
				dataTransactions["payment_method"]["token"] = res.data.id;
				var bearerPrivada = keyWompi.priv;
				//Realizar la transaction
				transaction = fetch(keyWompi.url + "transactions",
					{
						method: "POST",
						credentials: "same-origin",
						headers: {
							"Content-Type": "application/json",
							"Authorization": "Bearer " + bearerPrivada
						},
						body: JSON.stringify(dataTransactions),
					})
					.then(res => res.json())
					.then(res => {
						if (res.data) {
							return res.data.id;
						} else {
							return Promise.reject({ status: res.status, statusText: res.statusText });
						}
					})
					.catch(err => console.log("Error, with message:", err.statusText));
				return transaction;
				//Fin de la transaction
			} else {
				return Promise.reject({ status: res.status, statusText: res.statusText });
			}
		})
		.catch(err => console.log("Error, with message:", err.statusText));
	return transaction;

};
export const paymentNequi = (nequiNumber, dataTransactions, keyWompi) => {

	const datoCard = {
		payment_method: {
			type: "NEQUI",
			phone_number: nequiNumber
		}
	};
	Object.assign(dataTransactions, datoCard);
	var bearerPrivada = keyWompi.priv;
	//Realizar la transaction Nequi
	var transaction = fetch(keyWompi.url + "transactions",
		{
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerPrivada
			},
			body: JSON.stringify(dataTransactions),
		})
		.then(res => res.json())
		.then(res => {
			if (res.data) {
				return res.data.id;
			} else {
				return Promise.reject({ status: res.status, statusText: res.statusText });
			}
		})
		.catch(err => console.log("Error, with message:", err.statusText));
	return transaction;
};
//export const longPolling = async (transactions) => {
export const paymentPSE = (data, dataTransactions, codeFinancial, keyWompi) => {
	const datoCard = {
		payment_method: {
			type: "PSE",
			user_type: (data.datosContacto.social) ? 1 : 0,
			user_legal_id_type: (data.datosContacto.social) ? "NIT" : "CC",
			user_legal_id: data.datosContacto.id,
			financial_institution_code: codeFinancial,
			payment_description: "Compra desde tienda web Classic Jeans"
		}
	};
	const concatenacion = Object.assign(dataTransactions, datoCard);
	var bearerPrivada = keyWompi.priv;
	var transaction = fetch(keyWompi.url + "transactions",
		{
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerPrivada
			},
			body: JSON.stringify(dataTransactions),
		})
		.then(res => res.json())
		.then(res => {
			if (res.data) {
				//obtener la url de redireccionamiento obtener el estado de la transcation
				fetch(keyWompi.url + "transactions/" + res.data.id)
					.then(res => res.json())
					.then(res => {
						if (res.data.payment_method.extra.async_payment_url) {
							//obtener la url de redireccionamiento
							window.open(res.data.payment_method.extra.async_payment_url, "self");
							return res.data.id;
						}
					})
					.catch(err => console.log("Error, with message:", err.statusText));
				return res.data.id;
			} else {
				return Promise.reject({ status: res.status, statusText: res.statusText });
			}
		})
		.catch(err => console.log("Error, with message:", err.statusText));
	return transaction;
};
export const paymentBancocolombiaTransfer = (data, dataTransactions, keyWompi) => {
	const datoCard = {
		payment_method: {
			type: "BANCOLOMBIA_TRANSFER",
			user_type: "PERSON",
			payment_description: "Compra desde tienda web Classic Jeans",
			sandbox_status: "APPROVED",//Se quita para cuando se esta en PROD
		}
	};
	const concatenacion = Object.assign(dataTransactions, datoCard);
	var bearerPrivada = keyWompi.priv;
	//Realizar la transaction
	var transaction = fetch(keyWompi.url + "transactions",
		{
			method: "POST",
			credentials: "same-origin",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + bearerPrivada
			},
			body: JSON.stringify(dataTransactions),
		})
		.then(res => res.json())
		.then(res => {
			if (res.data) {
				//obtener la url de redireccionamiento obtener el estado de la transcation
				fetch(keyWompi.url + "transactions/" + res.data.id)
					.then(res => res.json())
					.then(res => {
						if (res.data.payment_method.extra.async_payment_url) {
							//obtener la url de redireccionamiento
							window.location = res.data.payment_method.extra.async_payment_url;
							return res.data.id;
						}
					})
					.catch(err => console.log("Error, with message:", err.statusText));
				return res.data.id;
			} else {
				return Promise.reject({ status: res.status, statusText: res.statusText });
			}
		})
		.catch(err => console.log("Error, with message:", err.statusText));
	return transaction;
};

export const generateReference = () => {
	//const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let random = Math.random().toString(36);
	return random;
};
export const FillData = (dataTransactions, userData) => {

	dataTransactions.customer_data.full_name = userData.datosContacto.firstName + " " + userData.datosContacto.lastName;
	dataTransactions.customer_data.phone_number = "57" + userData.datosContacto.celular;
	dataTransactions.customer_email = userData.datosContacto.email;
	dataTransactions.shipping_address.address_line_1 = userData.envio.address1;
	//dataTransactions.shipping_address.address_line_2 = userData.envio.address2;
	dataTransactions.shipping_address.city = userData.envio.cityName ? userData.envio.cityName : userData.envio.city;
	dataTransactions.shipping_address.region = userData.envio.deptName ? userData.envio.deptName : userData.envio.dept;
	dataTransactions.shipping_address.phone_number = "57" + userData.datosContacto.celular;
	return dataTransactions;
};

export const FillDataAddi = (dataTransactionsAddi, userData, visitorAddress) => {

	dataTransactionsAddi.client.idType = "CC";
	dataTransactionsAddi.client.idNumber = userData.addi.cedula;

	dataTransactionsAddi.client.firstName = userData.datosContacto.firstName;
	dataTransactionsAddi.client.lastName = userData.datosContacto.lastName;
	dataTransactionsAddi.client.email = userData.datosContacto.email;
	dataTransactionsAddi.client.cellphone = userData.datosContacto.celular;

	if (!isEmpty(visitorAddress)) {
		dataTransactionsAddi.client.address.lineOne = visitorAddress.address1;
		dataTransactionsAddi.client.address.city = visitorAddress.cityName;

		dataTransactionsAddi.billingAddress.lineOne = visitorAddress.address1;
		dataTransactionsAddi.billingAddress.city = visitorAddress.cityName;
	} else {
		let tmpUser = JSON.parse(localStorage.getItem("classic-user-fidelizado"));
		if (!isEmpty(tmpUser)) {
			dataTransactionsAddi.client.address.lineOne = tmpUser.address1;
			dataTransactionsAddi.client.address.city = tmpUser.cityName;

			dataTransactionsAddi.billingAddress.lineOne = tmpUser.address1;
			dataTransactionsAddi.billingAddress.city = tmpUser.cityName;
		} else {
			dataTransactionsAddi.client.address.lineOne = userData.envio.address1;
			dataTransactionsAddi.client.address.city = userData.envio.cityName;

			dataTransactionsAddi.billingAddress.lineOne = userData.envio.address1;
			dataTransactionsAddi.billingAddress.city = userData.envio.cityName;
		}
	}

	dataTransactionsAddi.shippingAddress.lineOne = userData.envio.address1;
	dataTransactionsAddi.shippingAddress.city = userData.envio.cityName ? userData.envio.cityName : userData.envio.city;

	return dataTransactionsAddi;
};

const ThirdParty = process.env.NEXT_PUBLIC_USE_TERCEROS;

export const event = (action, params) => {
	if (ThirdParty != "FALSE") {
		window.gtag("event", action, params);
	}
};

export const itemsGoogleAnalytics = (items) => {

	let parseItems = [];
	items?.products?.map(function (item) {
		parseItems.push({
			"item_id": item.codigoERP,
			"item_name": item.name,
			"affiliation": item.bodega,
			"currency": "COP",
			"item_variant": "Color-" + item.color + ", Talla-" + item.talla,
			"location_id": item.origen,
			"price": item.price,
			"quantity": item.qty
		});
	});
	return parseItems;
};

export const fbq = (action, params) => {
	if (ThirdParty != "FALSE") {
		window.fbq("track", action, params);
	}
	
};

export const itemsMeta = (items) => {

	let parseItems = [];
	items?.products?.map(function (item) {
		parseItems.push({
			"id": "wc_post_id_" + item.variationId,
			"quantity": item.qty
		});
	});
	return parseItems;
};

/*export const validarTarjeta = (target) => {
	var cards = {
		"American Express": /^3[47][0-9]{13}$/,
		"Mastercard": /^5[1-5][0-9]{14}$/,
		"Visa": /^4[0-9]{12}(?:[0-9]{3})?$/
	  };

	  for(var card in cards) {
		if (cards[card].test(target)) {
		  return card;
		}
	  }

	  return undefined;
}*/

export const processStatus = (status) => {
	switch (status) {
		case "PENDING":
			return "Solicitud Recibida";
		case "CANCELLED":
			return "Pedido Cancelado";
		case "FAILED":
			return "Pedido Rechazado";
		case "ON_HOLD":
			return "Solicitud Recibida";
		case "PROCESSING":
			return "Pedido Confirmado";
		case "ALISTAMIENTO":
			return "Pedido en Alistamiento";
		case "DESPACHADO":
			return "Pedido Despachado";
		case "COMPLETED":
			return "Pedido Entregado";
		case "REFUNDED":
			return "Pedido Devuelto";
		default:
			return status;
	}
};
export const getUpdatedOutStock = (products, newQty) => {

	const updatedItems = [];
	products.map((cartItem) => {
		updatedItems.push({
			key: cartItem.cartKey,
			quantity: parseInt(newQty)
		});

	});
	return updatedItems;
};

export const getImageFromCloudinary = (image, quality = "", w = 0, h = 0) => {

	if (image) {
		let tmpSplit = image?.split(".com");
		if (tmpSplit?.length > 1 && image.indexOf("/wp_content/") == -1 && image.indexOf("amazonaws") == -1) {

			const baseURL = "https://res.cloudinary.com/";
			const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
			let transformation = "/image/upload/f_auto,q_auto" + quality + "/v123/";

			if (w > 0 && h > 0) {
				transformation = "/image/upload/f_auto,q_auto" + quality + ",w_" + w + ",h_" + h + "/v123/";
			}

			const folder = "classicjeans-productos";
			tmpSplit = tmpSplit[1]?.split("classicjeans-productos");
			const publicId = tmpSplit[1];

			const resultURL = baseURL + cloudName + transformation + folder + publicId;
			return resultURL;

		} else if (image.indexOf("amazonaws") >= 0) {

			const baseURL = "https://res.cloudinary.com/";
			const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
			let transformation = "/image/upload/f_auto,q_auto" + quality + "/v123/";

			if (w > 0 && h > 0) {
				transformation = "/image/upload/f_auto,q_auto" + quality + ",w_" + w + ",h_" + h + "/v123/";
			}

			const folder = "classicjeans-productos";
			tmpSplit = tmpSplit[1]?.split(".");
			const publicId = tmpSplit[0];

			const resultURL = baseURL + cloudName + transformation + folder + publicId;
			return resultURL;
		}
		else {
			return image;
		}
	}

};

