import gql from "graphql-tag";

const UPDATE_ORDER_MUTATION = gql`
mutation UPDATE_ORDER_MUTATION( $input: UpdateOrderInput! ) {
    updateOrder(input: $input ) {
      order {
        billing {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          state
        }
        couponLines {
          nodes {
            discount
          }
        }
        orderId
        shipping {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          state
        }
        orderNumber
        status
        subtotal
        total
        totalTax
        shippingTotal
        customerNote
        paymentMethod
        transactionId
      }
    }
}
`;

export default UPDATE_ORDER_MUTATION;