import { useState, useEffect } from 'react';
import Image from 'next/image';
import clientConfig from "../../../client-config";

const MyImageComponent = ({ src, alt, width, height, quality, srcSet, retryDelay = 7000 }) => {
    const [currentSrc, setCurrentSrc] = useState(src);
    const [errorCount, setErrorCount] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (errorCount > 0) { // Si hubo un error, intenta recargar la imagen original
                setCurrentSrc(src + '?' + new Date().getTime()); // Truco para evitar la caché del navegador
            }
        }, retryDelay);

        return () => clearTimeout(timer);
    }, [errorCount, src, retryDelay]);

    const handleError = () => {
        if (errorCount < 1) { // Limita el número de reintentos
            setErrorCount(errorCount + 1);
        } else {
            setCurrentSrc(clientConfig.singleImagePlaceholder); // Si el reintento falla, muestra la imagen de respaldo
        }
    };

    return (
        <Image
            src={currentSrc}
            alt={alt}
            className="rounded-lg"
            width={width}
            height={height}
            onError={handleError}
            placeholder="blur"
            blurDataURL="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCAArACsDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAgEDAAb/xAAXEAEBAQEAAAAAAAAAAAAAAAAAAQIR/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/APZhSrO0FijKvQSitog2rPVOs9A6VejCBLRWiDahToUEhDCAaBaEH//Z"
            quality={quality}
            srcSet={srcSet}
            sizes="(max-width: 600px) 200px, (max-width: 768px) 300px, (max-width: 1024px) 400px, 614px"
        />
    );
};

export default MyImageComponent;
