const ThirdParty = process.env.NEXT_PUBLIC_USE_TERCEROS;

export const event = (action, params) => {
	if (ThirdParty != "FALSE") {
		window.gtag("event", action, params);
	}
};

export const itemsGoogleAnalytics = (items) => {

	let parseItems = [];
	items?.products?.map(function (item) {
		parseItems.push({
			"item_id": item.codigoERP,
			"item_name": item.name,
			"affiliation": item.bodega,
			"currency": "COP",
			"item_variant": "Color-" + item.color + ", Talla-" + item.talla,
			"location_id": item.origen,
			"price": item.price,
			"quantity": item.qty
		});
	});
	return parseItems;
};