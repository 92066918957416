import gql from "graphql-tag";

/**
 * GraphQL get client data.
 */
const GET_CLIENTE_QUERY = gql`query ($protectedBy:String, $username:String) {
  getUser(protectedBy: $protectedBy, username: $username) {
    celular
    email
    address1
    address2
    date
    country    
    dept
    city
    empleados
    firstName
    inferior
    lastName
    lastName2
    nickname
    profesion
    razonSocial
    sector
    sexo
    superior
    tipoTercero
    tipoIdentificacion
  }
}
`;

export default GET_CLIENTE_QUERY;
